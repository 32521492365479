import React from "react";
import { BsFillSuitHeartFill } from "react-icons/bs";
import { Tooltip } from "antd";
import { TrimTextWithEllipse } from "../utils/trimText";
import useProduct from "../hooks/useProduct"

const StandardProductCard = ({ product }) => {
	const {
		state:{favorite},
		actions:{
			removeFav,
			addToFav,
			addToCart,
			addToRecent,
			viewProduct
		}
	} = useProduct()

	return (
		<Tooltip title={product?.name}>
			<div onClick={() => {viewProduct(product)}} className="bg-blue-100 flex md:rounded-2xl rounded-lg flex-col items-center justify-between font-mont  h-[200px] md:w-[170px] w-[140px]  m-[5px] hover:cursor-pointer ">
				<span className="flex  self-end mr-[10px] mt-[10px] z-10">
					{favorite?.find((prod) => prod?._id === product?._id) ? (
						<BsFillSuitHeartFill
							className={` outline-white text-orange-400 text-[15px] `}
							onClick={() => {removeFav(product)}}
						/>
					) : (
						<BsFillSuitHeartFill
							className={` outline-white text-white text-[15px] `}
							onClick={() => {addToFav(product)}}
						/>
					)}
				</span>
				<img
					className="w-[100px] sm:w-[100px] h-[120px] mt-[-21px] object-cover"
					src={product?.images && product?.images[0]}
					alt={""}
					onClick={() => {viewProduct(product)}}
				/>
				<div className="flex flex-col mt-[0px] py-[8px]  items-center justify-center">
					<hr />
					<h2 className="font-bold text-[12px]">
						{TrimTextWithEllipse(product?.name, 12)}
					</h2>

					<p className="text-[10px] mb-1 font-semibold text-app-black  ">
						GHC {product?.price?.toLocaleString()}
					</p>

					<button
						onClick={(e) => {addToCart(e,product)}}
						className="rounded-md bg-dark-blue text-app-white px-[5px] font-semibold py-[2px] text-[10px] ">
						Add to Cart
					</button>
				</div>
			</div>
		</Tooltip>
	);
};

export default StandardProductCard;
