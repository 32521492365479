import React from "react";
import RelatedProducts from "./components/RelatedProducts";
import useProduct from "../../hooks/useProduct";
import {FaStar} from "react-icons/fa";
import {AddToCartAction} from "../../redux/actions/product.action";
import {updateUserAction} from "../../redux/actions/user.action";
import WideButton from "../../components/wideButton";
import {isNotNilOrEmpty} from "ramda-adjunct";

const ProductDetails = () => {

  const {state:{selectedProduct}, actions:{addToCart}} = useProduct()

  return (
    <div className="mb-16 md:pt-24 xl:pt-0">
      <div className="hero_container mx-auto">
        {isNotNilOrEmpty(selectedProduct) &&
            <div className="flex flex-col lg:flex-row gap-[30px] mb-[10px] bg-herobg">
              <div className="flex-1 lg:max-w-[40%] lg:h-[540px] grad rounded-lg flex justify-center items-center">
                <img
                    src={selectedProduct && selectedProduct?.images[0]}
                    alt=""
                    className="w-full max-w-[65%]"
                />
              </div>
              <div className="flex-1 bg-slate-700 bg-opacity-10 p-12 xl:p-20 rounded-lg flex flex-col justify-center">
                {/* title */}
                <div className="uppercase text-accent text-lg font-medium mb-2">
                  {selectedProduct?.name}
                </div>
                {/* title2 */}
                <h2 className="h2 mb-4">{selectedProduct?.brand}</h2>
                {/* desc */}
                <p className="mb-4">{selectedProduct?.description}</p>

                <div className=" flex flex-row items-center font-mont font-light">
                  <span className="mr-2"> Review:</span>
                  <span>
									{" "}
                    {[...Array(`${selectedProduct?.reviews}`)].map((_, index) => {
                      const isFilled = index < `${selectedProduct?.reviews+1}`;
                      return (
                          <span key={index}>
                        {isFilled ? (
                            <FaStar color="#ffc107" />
                        ) : (
                            <FaStar color="#e4e5e9" />
                        )}
                      </span>
                      );
                    })}
              </span>
                  {/*<span className="ml-[32px] mb-2">*/}
                  {/*  Status:{" "}*/}
                  {/*  {selectedProduct?.status*/}
                  {/*      ?.toLowerCase()*/}
                  {/*      .split("_")*/}
                  {/*      ?.map(*/}
                  {/*          (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)*/}
                  {/*      )*/}
                  {/*      ?.join(" ")}*/}
                  {/*</span>*/}

                </div>
                {/* price e button */}
                <div className="flex items-center gap-x-8">
                  {/* price */}
                  <div className="text-3xl text-accent font-semibold">
                    ${selectedProduct?.price}
                  </div>
                  <button onClick={(e) => {addToCart(e,selectedProduct, true)}} className="btn btn-accent">
                    Add to cart
                  </button>
                </div>
              </div>
            </div>
        }
        <RelatedProducts categoryTitle={'categoryTitle'} />
      </div>
    </div>
  );
};

export default ProductDetails;
