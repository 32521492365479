/* eslint-disable react/style-prop-object */
import {Input, Spin} from "antd";
import React, { useEffect, useState } from "react";
import WideButton from "../components/wideButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterAction } from "../redux/actions/user.action";
import useProduct from "../hooks/useProduct";
import {isNotNilOrEmpty} from "ramda-adjunct";

const RegisterScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const {state: {cartItems}} = useProduct()

	const { userInfo, error } = useSelector(
		(state) => state.userRegister
	);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [phone, setPhone] = useState("");
	const [loading, setLoading] = useState(false);

	const registerUser = async (e)  => {
		e.preventDefault();
		setLoading(true)
		if (!firstName || !lastName || !email || !phone || !password) {
			setLoading(false);
			return; // Exit the function if any required field is missing
		}
	await dispatch(
			userRegisterAction({
				firstname: firstName,
				lastname: lastName,
				email: email,
				mobile: phone,
				password
			})
		);
		setLoading(false)
	};

	useEffect(() => {
		if (userInfo?._id) {
			  isNotNilOrEmpty(cartItems) ? navigate("/cart"): navigate("/");
		}
	}, [navigate, userInfo]);
	return (
		<div className="flex pt-[50px] flex-col bg-background h-screen items-center w-full ">
			<img
				alt="bevets"
				src={"/assets/logo.png"}
				className="w-[120px] md:mt-0 cursor-pointer"
				onClick={()=>navigate("/")}
			/>
			<h3 className="font-bold text-[25px] mt-[30px] font-mont">Sign up Details</h3>
			<p className="mt-[15px] mb-[20px] font-mont">
				We just need you to fill in some details
			</p>

			<form className="md:w-[30%] w-[60%] authForm" submit={registerUser}>
				{error && <p className="my-[5px] text-red-400">{error}</p>}
				<div className="checkout-field">
					<label>Firstname</label>
					<Input
						placeholder="First Name"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					/>
				</div>

				<div className="checkout-field mt-4">
					<label>Lastname</label>
					<Input
						placeholder="Last Name"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					/>
				</div>

				<div className="checkout-field mt-4">
					<label>Phone No.</label>
					<Input
						placeholder="Phone"
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</div>

				<div className="checkout-field mt-4">
					<label>Email</label>
					<Input
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</div>

				<div className="checkout-field mt-4 mb-8">
					<label>Password</label>
					<Input.Password
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						placeholder="Password"
					/>
				</div>

				<button
					className="md:mt-[15px] mt-[6px] w-full md:py-[5px] py-[5px] text-[10px] md:text-[15px] font-semibold border-none outline-none bg-primary-blue family-inter rounded-2xl text-white hover:shadow-md"
					type="submit"
					onClick={registerUser}>
					  Register
					{loading && <Spin className="ml-2"/>}
				</button>
				<br />

				<p className="mt-[20px] text-[12px] text-center md:text-[15px] family-poppins">
					Already have an account?{" "}
					<button className="text-app-orange font-bold" onClick={()=>  navigate('/login')}>
						Login
					</button>
				</p>
			</form>

			<h6 className="mt-[30px] text-[10px] w-[35%] text-center">
				For further support, you may visit the Help Center or contact our
				customer service team.
			</h6>
		</div>
	);
};

export default RegisterScreen;
