import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    AddToCartAction,
    AddToFavAction,
    AddToRecentlyViewedAction,
    RemoveFromFavAction
} from "../redux/actions/product.action";
import {updateUserAction} from "../redux/actions/user.action";

const useProduct = () =>{

    const {
        products,
        loading: catLoading,
        error: catError
    } = useSelector((state) => state.productByCategory);

    const { product:selectedProduct, loading, error } = useSelector((state) => state.productById);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { favorite, cartItems } = useSelector((state) => state.cart);
    const { userInfo } = useSelector((state) => state.userLogin);

    const viewProduct = (product)=>{
        console.log({createProd: product})
            dispatch(AddToRecentlyViewedAction(product));
            navigate(`/product/${product?._id}`, {
                state: {
                    categoryName: product?.category?.name,
                    categoryId: product?.category?._id
                }
            });
        }

    const addToCart = (e,product, showCart= false)=>{
        e.stopPropagation()
            if (product?._id) {
                dispatch(AddToCartAction(product));
            }
            dispatch(
                updateUserAction(userInfo?._id, {
                    cart: cartItems?.map((item) => {
                        return {
                            product: item?.item?._id,
                            qty: item?.qty
                        };
                    })
                })
            );
            showCart && navigate('/cart')
    }

    const addToFav = (product)=>{
        dispatch(AddToFavAction(product));
    }

    const addToRecent = ()=>{

    }

    const removeFav = (product)=>{
        dispatch(RemoveFromFavAction(product))
    }

    return {
        state:{
           products,
           selectedProduct,
           catLoading,
           catError,
           favorite,
           cartItems
        },
        actions:{
           addToCart,
            addToFav,
            addToRecent,
            removeFav,
            viewProduct,
        }
    }
}

export default useProduct;