import React from "react";
import ProductSlider from "./ProductSlider";
import useProduct from "../../../hooks/useProduct";
import {Spin} from "antd";

const RelatedProducts = () => {

    const {state:{products:data, catLoading, catError}} = useProduct()

  return (
    <div className="mb-16">
      <div className="hero_container mx-auto">
          <div className="mt-[30px]">
              <h3 className="font-bold text-[15px] mb-[10px] family-poppins">
                  Similar Products {catLoading && <Spin className="ml-[3px]" />}{" "}
                  {catError && <p className="text-red-400">{catError}</p>}
              </h3>
              <hr />
          </div>
        <ProductSlider data={data}/>
      </div>
    </div>
  );
};

export default RelatedProducts;
