/* eslint-disable react/style-prop-object */
import React, { useEffect } from "react";
import Header from "../components/header";
import ScreenWithPadding from "../components/ScreenWithPadding";
import WideButton from "../components/wideButton";
import StandardProductCard from "../components/standardProductCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BsBasket3 } from "react-icons/bs";
import {
	getProductByIdAction,
	AddToCartAction,
	GetProductByCategoryAction,
	AddToFavAction
} from "../redux/actions/product.action";
import { Rate, Spin } from "antd";
import Carousel from "../components/lib/Carousel.js";
import { updateUserAction } from "../redux/actions/user.action";
import { FaStar } from "react-icons/fa";
import ProductDetails from "./product/ProductDetails";

const ProductByIdScreen = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { id } = useParams();
	const { loading, error } = useSelector((state) => state.productById);
	const { categoryName, categoryId } = location.state;

	useEffect(() => {
		dispatch(getProductByIdAction(id));
		dispatch(GetProductByCategoryAction(categoryId));
	}, [categoryId, dispatch, id]);

	return (
		<div className="bg-background">
			<Header />
			<ScreenWithPadding>
				<div className=" min-h-screen pb-[20px]">
					<div className="bg-secondary-blue rounded px-[5px] py-[8px] w-full">
						<h3 className="font-bold family-poppins">
							Home > Categories > {categoryName} {loading && <Spin />}
						</h3>
						{error && <p className="text-red-400"> {error}</p>}
					</div>
					<ProductDetails />
				</div>
			</ScreenWithPadding>
		</div>
	);
};

export default ProductByIdScreen;
